import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { IgxOverlayOutletDirective, IgxToggleActionDirective, OverlaySettings, IgxDropDownItemNavigationDirective, IgxIconComponent } from '@infragistics/igniteui-angular';
import { IgxDropDownBaseDirective } from '@infragistics/igniteui-angular/lib/drop-down/drop-down.base';

@Component({
    selector: 'app-button-row-button',
    templateUrl: './button-row-button.component.html',
    styleUrls: ['./button-row-button.component.scss'],
    standalone: true,
    imports: [IgxToggleActionDirective, IgxDropDownItemNavigationDirective, IgxIconComponent]
})
export class ButtonRowButtonComponent implements OnInit {

  constructor(private elRef: ElementRef) { }

  @Input() icon?: string;
  @Input() label?: string;
  @Input() gotDropdown: boolean = false;

  @Input() igxToggleAction!: any;
  @Input() igxDropDownItemNavigation!: IgxDropDownBaseDirective;
  @Input() igxToggleOutlet!: ElementRef<any> | IgxOverlayOutletDirective;
  @Input() overlaySettings!: OverlaySettings;
  
;
  
  private _enabled: boolean | null = true;
    
  @Input() set enabled(value: boolean | null) {
     this._enabled = value;
     this.elRef.nativeElement.style.pointerEvents  = value ? 'initial' : 'none';
     this.elRef.nativeElement.style.color = value ? '#000' : '#BCBCBC';
  }
  
  get enabled(): boolean | null {
      return this._enabled;
  }

  ngOnInit(): void {

    this.elRef.nativeElement.style.pointerEvents  = this.enabled ? 'initial' : 'none';
    this.elRef.nativeElement.style.color = this.enabled ? '#000' : '#BCBCBC';
  }

}
